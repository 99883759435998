/**
 * @author hongwei
 * @email 979956624@qq.com
 * @date 2021-12-08 19:12:58.626
 * @desc generated by yqg-cli@5.1.2
 */
export default {
    name: 'YqgHeader',

    mounted() {
        const {$refs: {header}} = this;
        this.scrollAcrossClass(header, 0, 'active');
    },

    methods: {
        scrollAcrossClass(el, breakPoint, className) {
            if (window) {
                if ((window.pageYOffset || document.scrollTop) > breakPoint) el.classList.add(className);

                window.addEventListener('scroll', () => {
                    const scrollTop = window.pageYOffset || document.scrollTop;

                    if (scrollTop > breakPoint) {
                        el.classList.add(className);

                        return;
                    }

                    el.classList.remove(className);
                });
            }
        }
    }
};
