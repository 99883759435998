var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-us" }, [
      _c("div", { staticClass: "container" }, [
        _c("span", { staticClass: "title" }, [_vm._v("关于我们")]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "first-content" }, [
            _vm._v(
              "\n                河北观然科技有限公司，成立于2021-10-27。由硅谷互联网技术精英专家团队组成，核心成员大部分曾服务于国内外知名互联网企业，\n                有丰富的技术咨询、大数据、互联网企业解决方案经验。\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                观然科技致力于为企业提供全栈式技术/信息咨询和技术解决方案。涉及业务领域涵盖：软件技术开发、技术推广、技术转让、技术咨询、技术服务；\n                企业管理咨询；经济贸易咨询；设计、制作、代理、发布广告；商务信息咨询（金融、债券、期货等需审批的项目除外）、软件咨询、经济信息咨询（中介除外）；\n                基础软件服务；应用软件服务；软件开发；计算机系统服务；计算机系统集成服务；数据处理服务。\n            "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }