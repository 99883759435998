/**
 * @author hongweihu
 * @email hongweihu@yangqianguan.com
 * @date 2019-12-04 16:37:23.969
 * @desc generated by yqg-cli@1.0.4
 */
import AboutUs from './component/about-us';
import Banner from './component/banner';
import YqgFooter from './component/yqg-footer';
import YqgHeader from './component/yqg-header';

export default {
    name: 'Index',

    title: '河北观然科技有限公司',

    head() {
        const content = '河北观然科技有限公司';

        return {meta: [{name: 'description', content}]};
    },

    components: {
        YqgHeader,
        Banner,
        AboutUs,
        YqgFooter
    }
};
