var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "copyright-info" }, [
        _c("div", { staticClass: "company-info" }, [
          _vm._v("\n                河北观然科技有限公司\n            "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "copyright",
            on: {
              click: function ($event) {
                return _vm.jumpTo("https://beian.miit.gov.cn/")
              },
            },
          },
          [_vm._v("\n                冀ICP备2022017517号-1\n            ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }