/**
 * @author hongwei
 * @email 979956624@qq.com
 * @date 2021-12-08 19:04:35.649
 * @desc generated by yqg-cli@5.1.2
 */

export default {
    name: 'AboutUs'
};
