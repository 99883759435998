/**
 * @author hongwei
 * @email 979956624@qq.com
 * @date 2021-12-08 19:13:16.428
 * @desc generated by yqg-cli@5.1.2
 */

export default {
    name: 'YqgFooter',

    methods: {
        jumpTo(url) {
            window.location.href = url;
        }
    }
};
